import Carousel from 'react-bootstrap/Carousel';
import VintageMange from '../components/vintageManager';
import YuvaManager from '../components/yuvaManager';
import { Container } from 'react-bootstrap';
import { useState, useEffect, createContext, useContext } from "react";
import { Link, Redirect, useNavigate, useHref, useHistory } from "react-router-dom";
import { JSEncrypt } from "jsencrypt";
import axios from "axios";
import * as constant from "../const";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../css/bootstrap.min.css';
import '../css/login.css';
import Mycontext from './useContextUser';
import VintageManager from '../components/vintageManager';


const Reload = () => {

  const yuvaData = sessionStorage.getItem('yuva');

  const topYuva = JSON.parse(yuvaData);


  if (topYuva) {
    return {
      TopYuvas: topYuva,

    }
  } else {
    return {
      TopYuvas: null,

    }
  }
}

function IndividualIntervalsExample() {

  const navigate = useNavigate();
  const [showVintage, setShowVintage] = useState(true);
  const [showYuva, setShowYuva] = useState(false);
  const [refreshToken, setToken] = useState('')


  const toggleComponents = () => {
    setShowVintage(prevState => !prevState);
    setShowYuva(prevState => !prevState);

  }

  useEffect(() => {
    const interval = setInterval(() => {
      toggleComponents();
    }, 14000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      submitHandle();
    }, 600000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    window.onpopstate = function (event) {
      navigate.go(1);
    };
  }, [])


  const getBranchDetails = async (jwt) => {
    
    var encrypt = new JSEncrypt();
    var publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtf+7W/9YogoqZBTnSvnoHCMS+QEQvfE7nAsj1ABoSsLJcIL8Zvoc0Jy/vD3CUnFbYhlltmVNhg32kXrHW02t9RokBLgkao32T1ggzgutwNpFQi9oqtMhzZytdKPm75x4tafkfH+Ee/Unp7N33AzF+YggvP2FocqrAAjumeCN9fXuz36x+/f6O9bM+A3nbsysf8IGf7FmeXI9uuK6kckJk38XCeCy8ox2CzNhwUwSQMmF9JJMLKH6bsuuJtjhyQelBITLa7f+q2ZzDqhohxBKA+tkv1v+DvA2I3MG6y4K5xh6JPaPXakiVCoEPFvrv0mRZ5E/NikJ+kNGbZ8ndmUWvwIDAQAB`;


    encrypt.setPublicKey(publicKey);

    var currTimestamp = Date.now();
    var time = "am";
    var ntid = window.sessionStorage.getItem('id');
    var ntID = JSON.parse(ntid);
   

    var branchCode = window.sessionStorage.getItem('br');
    var brCode = JSON.parse(branchCode);

    var encData = encrypt.encrypt(JSON.stringify({
        "userid":ntID,
        "timestamp": currTimestamp,
        "branchCode": brCode,
        "time": time
    }));

    const Response = await axios.post(constant.advmym, JSON.stringify({ntID, encData }), {
        headers: {
            "Content-Type": "application/json",
            "Authorization":`Bearer ${jwt}`
        },

    })
        .then((Response) => {
            console.log("Response Bname:", Response.data);
            window.sessionStorage.setItem('vintage', JSON.stringify(Response.data.finalTopManagers.top_vintages));
            window.sessionStorage.setItem('yuva', JSON.stringify(Response.data.finalTopManagers.top_yuvas));
            window.sessionStorage.setItem('branch', JSON.stringify(Response.data.finalTopManagers.BRANCH_NAME));
            window.sessionStorage.setItem('date', JSON.stringify(Response.data.finalTopManagers.DATE));
            window.sessionStorage.setItem('region', JSON.stringify(Response.data.finalTopManagers.REGION));
        }).catch((error) => {
            console.log("Error:", error)
            toast.error(error.response.data.message);
        })
}



  const ACLData = async (Token) => {

    var encrypt = new JSEncrypt();
    var publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtf+7W/9YogoqZBTnSvnoHCMS+QEQvfE7nAsj1ABoSsLJcIL8Zvoc0Jy/vD3CUnFbYhlltmVNhg32kXrHW02t9RokBLgkao32T1ggzgutwNpFQi9oqtMhzZytdKPm75x4tafkfH+Ee/Unp7N33AzF+YggvP2FocqrAAjumeCN9fXuz36x+/f6O9bM+A3nbsysf8IGf7FmeXI9uuK6kckJk38XCeCy8ox2CzNhwUwSQMmF9JJMLKH6bsuuJtjhyQelBITLa7f+q2ZzDqhohxBKA+tkv1v+DvA2I3MG6y4K5xh6JPaPXakiVCoEPFvrv0mRZ5E/NikJ+kNGbZ8ndmUWvwIDAQAB`;

    encrypt.setPublicKey(publicKey);

    var currTimestamp = Date.now();

    var ntid = window.sessionStorage.getItem('id');
    var ntID = JSON.parse(ntid);

    var encData = encrypt.encrypt(JSON.stringify({
      "userid": ntID,
      "timestamp": currTimestamp
    }));


    const Response = await axios.post(constant.vmym, JSON.stringify({ ntID, encData }), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${Token}`
      },

    })
      .then((Response) => {
        // setIsLoading(true){
        var data = Response.data;
        var vintageYuva = Response.data.finalTopManagers;
        var time=Response.data.time;

        if (vintageYuva && vintageYuva !== undefined && vintageYuva !== null) {
          window.sessionStorage.setItem('vintage', JSON.stringify(Response.data.finalTopManagers.top_vintages));
          window.sessionStorage.setItem('yuva', JSON.stringify(Response.data.finalTopManagers.top_yuvas));
          window.sessionStorage.setItem('branch', JSON.stringify(Response.data.finalTopManagers.BRANCH_NAME));
          window.sessionStorage.setItem('date', JSON.stringify(Response.data.finalTopManagers.DATE));
          window.sessionStorage.setItem('region', JSON.stringify(Response.data.finalTopManagers.REGION));
        }

      })
      .catch((error) => {
        // setIsLoading(true)
        console.log(error)

        if (error.response) {
          toast.error(error.response.data.message);
        }

        else {
          toast.error(error.message);
        }

      })

  }

  const submitHandle = async (e) => {

    var ntid = window.sessionStorage.getItem('id');
    var ntID = JSON.parse(ntid);
    var Cred = window.sessionStorage.getItem('cred');
    var TOKEN = JSON.parse(Cred);
    var Cred1 = window.sessionStorage.getItem('tkn');
    var TKN = JSON.parse(Cred1);

    // console.log("inside Refresh Token:",refreshToken);
   
    
    if (TKN && TKN !== null && TKN !== "" && TKN !== undefined) {
      var refreshtoken = TKN;
      setToken('');
      console.log("Refresh Token Genarated",refreshtoken);
    }
    if(TOKEN && TOKEN !== null && TOKEN !== "" && TOKEN !== undefined) {
      var refreshtoken = TOKEN
      sessionStorage.removeItem('cred');
      console.log("first Old Token",refreshtoken);
    }
    console.log("Outside Refresh Token:",refreshtoken);
    // console.log("Outside Refresh Token:",Token);

    const Response = await axios.post(constant.refreshJwt, JSON.stringify({ ntID }), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${refreshtoken}`
      },

    })
      .then((Response) => {
        console.log("Response:",Response)
        const token = Response.data.refresh_token;
        var TIME = window.sessionStorage.getItem('time');
        var Time = JSON.parse(TIME);
        console.log("TIME:",Time)

        if (token && token !== null && token !== undefined && token !== " " && Time==='pm') {
          window.sessionStorage.setItem('tkn', JSON.stringify(token));
          setToken(token);
          ACLData(token);
        }
        else if(Time==='am'){
          setToken(token);
          getBranchDetails(token);
        }
        else {
          toast.error("Internal server error");
        }

      })
      .catch((error) => {
        console.log(error)
      })
  }
// console.log("Outside Refresh Token:",refreshToken);

  return (

    < div className="h-56 sm:h-64 xl:h-80 2xl:h-96" >
      {
        showVintage && <VintageManager />
      }
      {
        showYuva && <YuvaManager />
      }

    </div>

  );
}

export default IndividualIntervalsExample;

import { HashRouter, Route, Routes} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Login from './components/login';
import VintageManager from './components/vintageManager';
import Acl from './components/layout';
import YuvaManager from './components/yuvaManager';
import Carousel from './components/Carousel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
 
  return (
    <>
      <ToastContainer position="top-center" />
      <HashRouter >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Acl" element={<Acl />} >
            <Route path="vintage" element={<Carousel />} />
            {/* <Route path="branches" element={<AdminLanding />} /> */}
          </Route>
        </Routes>
      </HashRouter>

    </>
  )
}

export default App

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/css2.css';
import '../css/yuva.css';
import AOS from "aos";
import "aos/dist/aos.css";
import Asset119 from '../images_yuva/Asset 119@1.5x.png';
import Asset76 from '../images_yuva/Asset 76@1.5x.png';
import Asset109 from '../images_yuva/Asset 109@1.5x.png';
import Picture from '../images_yuva/Picture@1.5x.png';
import Asset101 from '../images_yuva/Asset 101@1.5x.png';
import Asset102 from '../images_yuva/Asset 102@1.5x.png';
import Asset44 from '../images_yuva/Asset 44@1.5x.png';
import Asset42 from '../images_yuva/Asset 42@1.5x.png';
import Moment from 'react-moment';
import momnet from 'moment';

const yuva = () => {

    const yuvaData = sessionStorage.getItem('yuva');
    const Date = sessionStorage.getItem('date');
    const yuvaBranch = sessionStorage.getItem('branch');
    const yuvaRegion = sessionStorage.getItem('region');
    const region = JSON.parse(yuvaRegion);
    const branchName = JSON.parse(yuvaBranch);
    const date = JSON.parse(Date);
    const topYuva = JSON.parse(yuvaData);
    const FilterDat = [];

    for (var i = 0; i < topYuva.length; i++) {
        // console.log("Data:",topYuva[i]);
        if (topYuva[i].Rank != null && topYuva[i].Score != null && topYuva[i].Name != null) {
            FilterDat[i] = topYuva[i];
        }
    }

    if (FilterDat || topYuva || region || branchName || date) {
        return {
            TopYuvas: topYuva,
            region: region,
            branchName: branchName,
            date: date,
            filterData: FilterDat
        }
    } else {
        return {
            TopYuvas: null,
            region: null,
            branchName: null,
            date: null,
            filterData: null
        }
    }
}

const YuvaManager = () => {
    const navigate = useNavigate();
    const [showUpdatedData, setShowUpdatedData] = useState(false)
    const[DATE,setDATE]=useState('')

    useEffect(() => {
        AOS.init({
            // Global settings:
            offset: 0, // offset (in px) from the original trigger point

            duration: 400, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations


            once: true,
        })
        AOS.refresh();
    }, []);
    
    setTimeout(function () {
        document.getElementById('div1').style.visibility = "visible";
    }, 1000);

    setTimeout(function () {
        document.getElementById('div2').style.visibility = "visible";
    }, 1500);

    setTimeout(function () {
        document.getElementById('div3').style.visibility = "visible";
    }, 2000);

    setTimeout(function () {
        document.getElementById('div4').style.visibility = "visible";
    }, 3000);

    setTimeout(function () {
        document.getElementById('div5').style.visibility = "visible";
    }, 3500);

    setTimeout(function () {
        document.getElementById('div6').style.visibility = "visible";
    }, 4000);


    setTimeout(function () {
        document.getElementById('div7').style.visibility = "visible";
    }, 5000);

    setTimeout(function () {
        document.getElementById('div8').style.visibility = "visible";
    }, 5500);

    setTimeout(function () {
        document.getElementById('div9').style.visibility = "visible";
    }, 6000);

    setTimeout(function () {
        document.getElementById('div10').style.visibility = "visible";
    }, 7000);

    setTimeout(function () {
        document.getElementById('div11').style.visibility = "visible";
    }, 7500);

    setTimeout(function () {
        document.getElementById('div12').style.visibility = "visible";
    }, 8000);

    setTimeout(function () {
        document.getElementById('div13').style.visibility = "visible";
    }, 9000);

    setTimeout(function () {
        document.getElementById('div14').style.visibility = "visible";
    }, 9500);

    setTimeout(function () {
        document.getElementById('div15').style.visibility = "visible";
    }, 10000);

    const { filterData, region, branchName, date } = yuva();

    useEffect(() => {
        window.onpopstate = function (event) {
            navigate('/acl/vintage');
        };
    }, [])



    useEffect(() => {

        const inDateTime=date;
        const parseDate= momnet(inDateTime,'DD-MMM-YY hh.mm.ss.SSSSSSSSS A')
        const formatDate=parseDate.format('MMM DD, YYYY')

        setDATE(formatDate)
        if (inDateTime || inDateTime !== null || inDateTime !== undefined) {
            var currDate = new Date();
            // var NewDate = new Date(date);
            const NewDate=parseDate.format('M/D/YYYY')
            
            if (NewDate=== currDate.toLocaleDateString()) {
                setShowUpdatedData(false)
            
            }
            else {
                setShowUpdatedData(true)
            }
        }
    }, [])



    return (
        <>
            <div className="yuvaMain">
                <div className=" bodybackground-image  img-responsive" >


                    <div className="container">

                        <div className="text-center sticky-top">
                            <img src={Asset119} className="img-responsive header-img card-img" />
                            <div className="card-img-overlay header-contain " data-aos="zoom-in" data-aos-delay="200" style={{ padding: "1rem" }}>
                                <h1 className=" header-contain-color text-h1-header">AGENCY CHAMPS LEADERBOARD - {DATE}</h1>

                                <h1 className=" header-contain-color">Branch name: {branchName.toString().toUpperCase()} | Region: {region.toString().toUpperCase()} </h1>
                            </div>
                        </div>

                        <div className="text-center  margin-top-menu" >
                            <div className="container-fluid ">

                                <div className="row " data-aos="zoom-in" data-aos-delay="400">
                                    <div className="col-sm-4">
                                        <div className="row left-side-row-parent">
                                            <img src={Picture} className="image img-responsive left-image-round " />
                                            <img src={Asset76} className="img-responsive left-image-logo " />
                                        </div>
                                    </div>

                                    <div className="col-sm-8  "  >

                                        <div className="row ">
                                            <h1 className="  text-h1-vintage-menu  margin-top-menu-contain " data-aos="zoom-in" data-aos-delay="400" >TOP 5 Yuva Managers</h1>


                                            <div className="col-3 col-sm-2 menu-image " data-aos="zoom-in" data-aos-delay="600" style={{ paddingLeft: "0px", paddingRight: "0px" }}>

                                                <div className="row "  >
                                                    <img src={Asset109} className="img-responsive" />
                                                    <div className="col-sm-12 "  >

                                                        <div className="row "  >
                                                            <div className="col-sm-3  text-center"  ></div>

                                                            <div className="col-sm-3 text-center "  >
                                                                <div className="menu-top-contain-table">
                                                                    <h1 className="  text-h1-rank-head text-white " >Rank</h1>

                                                                </div>
                                                            </div>


                                                            <div className="col-sm-3  text-center"  ></div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row margin-top"   >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-3  text-center"  ></div>
                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset101} className="number-img-max-width" />
                                                                </div>

                                                            </div>
                                                            <div className="col-sm-2 text-center "  >
                                                                <div className="img-number-left trans1" >
                                                                    {
                                                                        filterData && filterData[0] != undefined ? <h1 className=" span-font-number number-margin-bottom " id="div1" style={{ visibility: "hidden" }} >{filterData[0].Rank}</h1> : <h1 className=" span-font-number number-margin-bottom " id="div1">-</h1>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset102} className="number-img-max-width" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3  text-center"  ></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-3  text-center"  ></div>
                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset101} className="number-img-max-width" />
                                                                </div>

                                                            </div>
                                                            <div className="col-sm-2 text-center "  >
                                                                <div className="img-number-left trans2">
                                                                    {
                                                                        filterData && filterData[1] !== undefined ? <h1 className=" span-font-number number-margin-bottom" id="div4" style={{ visibility: "hidden" }}>{filterData[1].Rank} </h1> : <h1 className=" span-font-number number-margin-bottom" id="div4">-</h1>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset102} className="number-img-max-width" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3  text-center"  ></div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-3  text-center"  ></div>
                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset101} className="number-img-max-width" />
                                                                </div>

                                                            </div>
                                                            <div className="col-sm-2 text-center "  >
                                                                <div className="img-number-left trans3">
                                                                    {/* <!--<span className=" span-font-number ">1</span>--> */}
                                                                    {
                                                                        filterData && filterData[2] !== undefined ? <h1 className=" span-font-number number-margin-bottom" id="div7" style={{ visibility: "hidden" }}>{filterData[2].Rank} </h1> : <h1 className=" span-font-number number-margin-bottom" id="div7">-</h1>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset102} className="number-img-max-width" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3  text-center"  ></div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-3  text-center"  ></div>
                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset101} className="number-img-max-width" />
                                                                </div>

                                                            </div>
                                                            <div className="col-sm-2 text-center "  >
                                                                <div className="img-number-left trans4">
                                                                    {
                                                                        filterData && filterData[3] != undefined ? <h1 className=" span-font-number number-margin-bottom" id="div10" style={{ visibility: "hidden" }} >{filterData[3].Rank} </h1> : <h1 className=" span-font-number number-margin-bottom" id="div10">-</h1>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset102} className="number-img-max-width" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3  text-center"  ></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row margin-top "  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-3  text-center"  ></div>
                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset101} className="number-img-max-width" />
                                                                </div>

                                                            </div>
                                                            <div className="col-sm-2 text-center "  >
                                                                <div className="img-number-left trans5">
                                                                    {
                                                                        filterData && filterData[4] !== undefined ? <h1 className=" span-font-number number-margin-bottom" id="div13" style={{ visibility: "hidden" }} >{filterData[4].Rank} </h1> : <h1 className="span-font-flname-and-score margin-top extra5" id="div13" >-</h1>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-2  text-center"  >
                                                                <div className="img-number-left ">
                                                                    <img src={Asset102} className="number-img-max-width" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3  text-center"  ></div>
                                                        </div>
                                                    </div>
                                                </div>





                                            </div>

                                            <div className="col-1 col-sm-1-space" data-aos="zoom-in" data-aos-delay="600" ></div>

                                            <div className="col-3 col-sm-4 menu-image-FlName" data-aos="zoom-in" data-aos-delay="600" style={{ paddingLeft: "0px", paddingRight: "0px" }}>



                                                <div className="row margin-top"  >
                                                    <img src={Asset109} className="img-responsive" style={{ height: "11vh" }} />
                                                    <div className="col-sm-12 "  >

                                                        <div className="row "  >
                                                            <div className="col-sm-2  text-center"  ></div>

                                                            <div className="col-sm-4 text-center "  >
                                                                <div className="menu-top-contain-table ">
                                                                    <h1 className="  text-h1-flname-head text-white ">FL Name</h1>

                                                                </div>
                                                            </div>


                                                            <div className="col-sm-3  text-center"  ></div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                            <div className="col-sm-10 text-center"  >
                                                                <div className="trans1">
                                                                    {
                                                                        filterData && filterData[0] != undefined ? <h4 className="span-font-flname-and-score margin-top extra1 " id="div2" style={{ visibility: "hidden" }} > {filterData[0].Name.toString().toUpperCase()}</h4> : <h1 className="span-font-flname-and-score margin-top extra1 " id="div2" > -</h1>
                                                                    }
                                                                </div>
                                                                <img src={Asset44} className="img-responsive line-img-FL-Name" />

                                                            </div>


                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"   ></div>

                                                            <div className="col-sm-10 text-center "  >
                                                                <div className="trans2">
                                                                    {
                                                                        filterData && filterData[1] !== undefined ? <h1 className="span-font-flname-and-score margin-top extra2" id="div5" style={{ visibility: "hidden" }} >{filterData[1].Name.toString().toUpperCase()} </h1> : <h1 className="span-font-flname-and-score margin-top extra2" id="div5">-</h1>
                                                                    }
                                                                </div>
                                                                <img src={Asset44} className="img-responsive line-img-FL-Name" />

                                                            </div>


                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                            <div className="col-sm-10 text-center "  >
                                                                <div className="trans3">
                                                                    {
                                                                        filterData && filterData[2] !== undefined ? <h1 className="span-font-flname-and-score margin-top extra3" id="div8" style={{ visibility: "hidden" }} > {filterData[2].Name.toString().toUpperCase()} </h1> : <h1 className="span-font-flname-and-score margin-top extra3" id="div8">-</h1>
                                                                    }
                                                                </div>
                                                                <img src={Asset44} className="img-responsive line-img-FL-Name" />

                                                            </div>


                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row margin-top" >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                            <div className="col-sm-10 text-center "  >
                                                                <div className="trans4">
                                                                    {
                                                                        filterData && filterData[3] != undefined ? <h1 className="span-font-flname-and-score margin-top extra4" id="div11" style={{ visibility: "hidden" }} >{filterData[3].Name.toString().toUpperCase()} </h1> : <h1 className="span-font-flname-and-score margin-top extra4" id="div11">-</h1>
                                                                    }
                                                                </div>
                                                                <img src={Asset44} className="img-responsive line-img-FL-Name" />

                                                            </div>


                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row margin-top "  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                            <div className="col-sm-10 text-center trans5"  >
                                                                {
                                                                    filterData && filterData[4] != undefined ? <h1 className="span-font-flname-and-score margin-top extra5" id="div14" style={{ visibility: "hidden" }} > {filterData[4].Name.toString().toUpperCase()} </h1> : <h1 className="span-font-flname-and-score margin-top extra5" id="div14" >-</h1>
                                                                }


                                                            </div>


                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>




                                            </div>

                                            <div className="col-1 col-sm-1-space" data-aos="zoom-in" data-aos-delay="600" ></div>

                                            <div className="col-3 col-sm-2 menu-image z-in" data-aos="zoom-in" data-aos-delay="600" style={{ paddingLeft: "0px", paddingRight: "0px" }}>



                                                <div className="row margin-top"  >
                                                    <img src={Asset109} className="img-responsive" />
                                                    <div className="col-sm-12 "  >

                                                        <div className="row "  >
                                                            <div className="col-sm-3  text-center"  ></div>

                                                            <div className="col-sm-3 text-center "  >
                                                                <div className="menu-top-contain-table ">
                                                                    <h1 className="  text-h1-score-head text-white ">Score</h1>

                                                                </div>
                                                            </div>


                                                            <div className="col-sm-3  text-center"  ></div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                            <div className="col-sm-10 text-center "  >
                                                                <div className="trans1">
                                                                    {
                                                                        filterData && filterData[0] != undefined ? <h1 className="span-font-flname-and-score margin-top" id="div3" style={{ visibility: "hidden" }}> {filterData[0].Score} </h1> : <h1 className="span-font-flname-and-score margin-top" id="div3">-</h1>
                                                                    }
                                                                </div>
                                                                <img src={Asset44} className="img-responsive line-img-Score" />

                                                            </div>


                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                            <div className="col-sm-10 text-center"  >
                                                                <div className="trans2">
                                                                    {
                                                                        filterData && filterData[1] != undefined ? <h1 className="span-font-flname-and-score margin-top" id="div6" style={{ visibility: "hidden" }}>{filterData[1].Score} </h1> : <h1 className="span-font-flname-and-score margin-top" id="div6">-</h1>
                                                                    }
                                                                </div>
                                                                <img src={Asset44} className="img-responsive line-img-Score" />

                                                            </div>


                                                            <div className="col-sm-1-space text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                            <div className="col-sm-10 text-center "  >
                                                                <div className="trans3">
                                                                    {
                                                                        filterData && filterData[2] !== undefined ? <h1 className="span-font-flname-and-score margin-top" id="div9" style={{ visibility: "hidden" }} > {filterData[2].Score} </h1> : <h1 className="span-font-flname-and-score margin-top" id="div9">-</h1>
                                                                    }
                                                                </div>
                                                                <img src={Asset44} className="img-responsive line-img-Score" />

                                                            </div>


                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row margin-top"  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                            <div className="col-sm-10 text-center "  >
                                                                <div className="trans4">
                                                                    {
                                                                        filterData && filterData[3] !== undefined ? <h1 className="span-font-flname-and-score margin-top" id="div12" style={{ visibility: "hidden" }}> {filterData[3].Score} </h1> : <h1 className="span-font-flname-and-score margin-top" id="div12">-</h1>
                                                                    }
                                                                </div>
                                                                <img src={Asset44} className="img-responsive line-img-Score" />

                                                            </div>


                                                            <div className="col-sm-1-space text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row margin-top "  >
                                                    <div className="col-sm-12 "  >
                                                        <div className="row "  >
                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                            <div className="col-sm-10 text-center "  >
                                                                <div className="trans5">
                                                                    {
                                                                        filterData && filterData[4] != undefined ? <h1 className="span-font-flname-and-score margin-top" id="div15" style={{ visibility: "hidden" }} > {filterData[4].Score} </h1> : <h1 className="span-font-flname-and-score margin-top" id="div15" >-</h1>
                                                                    }
                                                                </div>


                                                            </div>


                                                            <div className="col-sm-1-space  text-center"  ></div>

                                                        </div>
                                                    </div>
                                                </div>









                                            </div>






                                        </div>



                                    </div>



                                </div>







                            </div>
                        </div>


                        <div className="fixed-bottom1 ">
                            <div className="text-center ">
                                <div className="row footer-div-row ">
                                    <div className="col-sm-4">
                                        <img src={Asset42} className="img-responsive footer-img" />
                                    </div>
                                    <div className="col-sm-8">

                                        <div className="row">
                                            <p className="footer-text-top" style={{ color: "#ffffff" }}><b>Strictly for internal circulation only, should not be further circulated/used for general presentation to a prospect/general public.</b></p>

                                        </div>
                                        {
                                            showUpdatedData ? <span className="updatedData">Updated data comming soon !</span> : null
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
export default YuvaManager;
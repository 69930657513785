import { useState, useEffect, createContext, useContext } from "react";
import { Link, Redirect, useNavigate, useHref,useLocation } from "react-router-dom";
import { JSEncrypt } from "jsencrypt";
import axios from "axios";
import * as constant from "../const";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../css/bootstrap.min.css';
import '../css/login.css';
import Select from 'react-select';
import Mycontext from "./useContextUser";
import Loader from 'react-loader';


const Login = () => {
    const [login, setLogin] = useState(
        {
            "userid": "",
            "timestamp": "",
            "password": ""
        }
    )


    const [role, setRole] = useState([]);

    const [token, setToken] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(undefined);
    const navigate = useNavigate();
    const [show, setShow] = useState(true);
    const [adminShow, setAdminShow] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedOptionsBN, setSelectedOptionsBN] = useState();
    const [branchCode, setBranchCode] = useState();
    const [branchName, setBranchName] = useState();

    const location = useLocation()

    const handleSelect = (data) => {
        setSelectedOptions(data);
    }



    const handleSelectBN = (data) => {
        console.log("handle", data.value);
        setSelectedOptionsBN(data);
    }

    useEffect (()=>{
        if (location.pathname == '/'){
            sessionStorage.clear();
        }
    },[])

    const getBName = async (e) => {
        e.preventDefault()
        setIsLoading(false)
        var encrypt = new JSEncrypt();
        var publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtf+7W/9YogoqZBTnSvnoHCMS+QEQvfE7nAsj1ABoSsLJcIL8Zvoc0Jy/vD3CUnFbYhlltmVNhg32kXrHW02t9RokBLgkao32T1ggzgutwNpFQi9oqtMhzZytdKPm75x4tafkfH+Ee/Unp7N33AzF+YggvP2FocqrAAjumeCN9fXuz36x+/f6O9bM+A3nbsysf8IGf7FmeXI9uuK6kckJk38XCeCy8ox2CzNhwUwSQMmF9JJMLKH6bsuuJtjhyQelBITLa7f+q2ZzDqhohxBKA+tkv1v+DvA2I3MG6y4K5xh6JPaPXakiVCoEPFvrv0mRZ5E/NikJ+kNGbZ8ndmUWvwIDAQAB`;


        encrypt.setPublicKey(publicKey);

        var currTimestamp = Date.now();
        var time = "am";

        if (!selectedOptionsBN || !selectedOptionsBN.value || selectedOptionsBN.value === null || selectedOptionsBN.value === undefined) {
            toast.error("Please select Branch");
            return false;
        }

        const Slice = selectedOptionsBN.value.split(" :- ");
        const brCode = Slice[1];
        window.sessionStorage.setItem('br',JSON.stringify(brCode));
        if (!brCode || brCode === null || brCode === undefined) {
            toast.error("Selected branch is not valid");
            return false;
        }

        var ntID= login.userid;
        var encData = encrypt.encrypt(JSON.stringify({
            "userid": login.userid,
            "timestamp": currTimestamp,
            "branchCode": brCode,
            "time": time
        }));

        const Response = await axios.post(constant.advmym, JSON.stringify({ntID, encData }), {
            headers: {
                "Content-Type": "application/json",
                "Authorization":`Bearer ${token}`
            },

        })
            .then((Response) => {
                setIsLoading(true)
                console.log("Response Bname:", Response.data);
                window.sessionStorage.setItem('vintage', JSON.stringify(Response.data.finalTopManagers.top_vintages));
                window.sessionStorage.setItem('yuva', JSON.stringify(Response.data.finalTopManagers.top_yuvas));
                window.sessionStorage.setItem('branch', JSON.stringify(Response.data.finalTopManagers.BRANCH_NAME));
                window.sessionStorage.setItem('date', JSON.stringify(Response.data.finalTopManagers.DATE));
                window.sessionStorage.setItem('region', JSON.stringify(Response.data.finalTopManagers.REGION));
                navigate('/acl/vintage')
            }).catch((error) => {
                setIsLoading(true)
                console.log("Error:", error)
                toast.error(error.response.data.message);
            })
    }


    const changeHandle = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setLogin((prelogin) => ({
            ...prelogin, [name]: value
        }))
    };

    const [FToken, setFToken] = useState('');
    // const role1 = {role};
    useEffect(() => {
        window.onpopstate = function (event) {
            navigate('/');
            sessionStorage.clear();
        };
    }, [])


    const ACLData = async (Token) => {
        console.log(login.userid);
        // e.preventDefault()
        setIsLoading(false)
        var encrypt = new JSEncrypt();
        var LpublicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtf+7W/9YogoqZBTnSvnoHCMS+QEQvfE7nAsj1ABoSsLJcIL8Zvoc0Jy/vD3CUnFbYhlltmVNhg32kXrHW02t9RokBLgkao32T1ggzgutwNpFQi9oqtMhzZytdKPm75x4tafkfH+Ee/Unp7N33AzF+YggvP2FocqrAAjumeCN9fXuz36x+/f6O9bM+A3nbsysf8IGf7FmeXI9uuK6kckJk38XCeCy8ox2CzNhwUwSQMmF9JJMLKH6bsuuJtjhyQelBITLa7f+q2ZzDqhohxBKA+tkv1v+DvA2I3MG6y4K5xh6JPaPXakiVCoEPFvrv0mRZ5E/NikJ+kNGbZ8ndmUWvwIDAQAB`;

        var aclPublicKey = ``;
        encrypt.setPublicKey(LpublicKey);

        var currTimestamp = Date.now();

        var ntID = login.userid;

        var encData = encrypt.encrypt(JSON.stringify({
            "userid": login.userid,
            "timestamp": currTimestamp
        }));


        const Response = await axios.post(constant.vmym, JSON.stringify({ ntID, encData }), {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Token}`
            },

        })
            .then((Response) => {
                
                setIsLoading(true)
                var data = Response.data;
                var vintageYuva = Response.data.finalTopManagers;

                var Time = Response.data.time;
                window.sessionStorage.setItem('time', JSON.stringify(Time));
                console.log("Time:", Time)

                if (Time === "am" && Time && Time !== undefined && Time !== null) {
                    console.log("Inside Time")
                    var codes = [];
                    var parseCodes = [];
                    var branch = [];
                    var parseBranch = [];
                    var brachCodeName = [];

                    for (var i = 0; i < data.branches.length; i++) {
                        brachCodeName[i] = data.branches[i].BRANCH_NAME + " :- " + data.branches[i].BRANCH_CODE;

                        codes[i] = JSON.stringify({ value: brachCodeName[i], label: brachCodeName[i] });
                        parseBranch[i] = JSON.parse(codes[i]);
                    }
                    setBranchCode(parseBranch);
                    setBranchName(parseBranch);
                    setAdminShow(true);
                    setShow(false);
                }
                else if (vintageYuva && vintageYuva !== undefined && vintageYuva !== null) {
                    window.sessionStorage.setItem('vintage', JSON.stringify(Response.data.finalTopManagers.top_vintages));
                    window.sessionStorage.setItem('yuva', JSON.stringify(Response.data.finalTopManagers.top_yuvas));
                    window.sessionStorage.setItem('branch', JSON.stringify(Response.data.finalTopManagers.BRANCH_NAME));
                    window.sessionStorage.setItem('date', JSON.stringify(Response.data.finalTopManagers.DATE));
                    window.sessionStorage.setItem('region', JSON.stringify(Response.data.finalTopManagers.REGION));
                    navigate('/acl/vintage')
                }
                else {
                    toast.error("Something went wrong !");
                }

            })
            .catch((error) => {
                setIsLoading(true)

                if (error.response) {
                    toast.error(error.response.data.message);
                } 
                
                else {
                    toast.error(error.message);
                }

            })

    }

    const userLoginEnc = (loginData) => {
        var encrypt = new JSEncrypt();
        var publicKey = `MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgB7BOGQ0LnBTCuL4lhNYFKW
        wgGMBHQpYpxUOO8ACfBn7IW7N2hET5R2xNXjdtm2a2h681l2payBuMPMSGYBXlkl
        h36CPtwMUlBv0gvTGeCjBpoyhWmOAdHx7CWy1O8+H2QvAc6i+mRtEhCUWAjjWgU9
        09oXgiToro4TL/+A4k1tCTM60ATa7579BJxVkj+zZpmSc9plwVrA39aj1v68Vbes
        tCWFIZQO7yRwMx8sZeZ2B0+wGAftZI9aLGx+fGchCqXmqaIoe4IylBlPM410yHYh
        jtxS2R/YZdlzcQwSYQ4L4nWRyaGcS8pZWJsSNhjehhhyM7w9+7/X3jD7QBFMfsAR
        6g1g+3cK3J4bTrooS6ELybXNuyxgLOSmsMjn2j96laPOhdtC2OSQnwfScDpgS1PT
        qY+5TBl6TDQE2SuWab8Wv+F5WgNLMDIMdSZLK7mXty+giekG7qUzC8RNCY2PkHmD
        ZedoiR1Q5f255TDZer/0EmUq6H1yUsjqbsimZlLyjyWtmFx+WNP5UBUZyU+Atkl1
        A3YplxysHDbLggNrHcbZrrdMFBd+UWh3DBwLYdIZmgmpm4h0oqQ/XmSPIMl9RRth
        auRUeroY6UV1rPHKEidFH+GW8tKIiTjXkRCQVH5aOs8tS78vWmyUjRarZLkrvNXX
        JbNud+Tg0VWWi5gI5dSDTwIDAQAB`;
        encrypt.setPublicKey(publicKey);

        var result = encrypt.encrypt(loginData);

        return result;

    }

    const submitHandle = async (e) => {
        console.log(login.userid);
        e.preventDefault()
        setIsLoading(false)
        var encrypt = new JSEncrypt();
        var publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtf+7W/9YogoqZBTnSvnoHCMS+QEQvfE7nAsj1ABoSsLJcIL8Zvoc0Jy/vD3CUnFbYhlltmVNhg32kXrHW02t9RokBLgkao32T1ggzgutwNpFQi9oqtMhzZytdKPm75x4tafkfH+Ee/Unp7N33AzF+YggvP2FocqrAAjumeCN9fXuz36x+/f6O9bM+A3nbsysf8IGf7FmeXI9uuK6kckJk38XCeCy8ox2CzNhwUwSQMmF9JJMLKH6bsuuJtjhyQelBITLa7f+q2ZzDqhohxBKA+tkv1v+DvA2I3MG6y4K5xh6JPaPXakiVCoEPFvrv0mRZ5E/NikJ+kNGbZ8ndmUWvwIDAQAB`;

        window.sessionStorage.setItem('id', JSON.stringify(login.userid));
        if (!login.userid || login.userid === "") {
            toast.error("Please enter NTID");
            return false;
        }
        if (!login.password || login.password === "") {
            toast.error("Please enter Password");
            return false;
        }

        var ntID = userLoginEnc(login.userid);
        var password = userLoginEnc(login.password);


        encrypt.setPublicKey(publicKey);


        var currTimestamp = Date.now();
        var time = "am";
        var branchCode = "072";

        var encData = encrypt.encrypt(JSON.stringify({
            "userid": login.userid,
            "timestamp": currTimestamp
        }));

        const Response = await axios.post(constant.jwt, JSON.stringify({ ntID, password, encData }), {
            headers: {
                "Content-Type": "application/json"
            },

        })
            .then((Response) => {
                setIsLoading(true)

                const token = Response.data.Token;
                window.sessionStorage.setItem('cr',JSON.stringify({token}))

                if (!Response.data.Token || Response.data.Token === "" || Response.data.Token === undefined || Response.data.Token === null) {
                    toast.error(Response.data.err_msg);
                    
                    return false;
                }
                else if (token && token !== null && token !== undefined && token !== " ") {
                    window.sessionStorage.setItem('cred', JSON.stringify(token));
                    setToken(token)
                    ACLData(token);
                }
                else {
                    toast.error("Internal server error");
                }

            })
            .catch((error) => {
                setIsLoading(true)
                if (error.response.data.error_code === "525") {
                    toast.error("Unsuccessful Login! User Not Found in IPRU LDAP");
                }

                else if (error.response.data.error_code === "52e") {
                    toast.error('Unsuccessful Login! Invalid Credentials');
                }
                else if (error.response.data.error_code === "530") {
                    toast.error("Unsuccessful Login! User Not Permitted to logon at this Time");
                }
                else if (error.response.data.error_code === "531") {
                    toast.error("Unsuccessful Login! User Not Permitted to logon at this WorkStation");
                }
                else if (error.response.data.error_code === "532") {
                    toast.error("Unsuccessful Login! User Password is expired");
                }
                else if (error.response.data.error_code === "533") {
                    toast.error("Unsuccessful Login! User Password is expired");
                }
                else if (error.response.data.error_code === "701") {
                    toast.error('Unsuccessful Login! User Account is expired');
                }
                else if (error.response.data.error_code === "773") {
                    toast.error("Unsuccessful Login! User must reset password");
                }
                else if (error.response.data.error_code === "775") {
                    toast.error("Unsuccessful Login! User Account is Locked!");
                }

            })

    }

    const userLogin = (
        <>

            <Modal show={show} backdrop="static">
                <Modal.Header>
                    <Modal.Title><h4>Login</h4></Modal.Title>
                </Modal.Header>
                <form style={{ padding: "0.5rem" }} onSubmit={submitHandle} >
                    <Modal.Body>
                        <Loader loaded={isLoading} content="Loading..."></Loader>
                        <div className="form-group row">
                            <div className="col-sm-1"></div>
                            <label htmlFor="userid" className="col-sm-2 col-form-label" style={{ paddingLeft: "2px" }}><b>Username</b></label>
                            <div className="col-sm-8">
                                <input type="text" id="userid" name="userid" value={login.userid} required className="form-control" id="idinputuserid"
                                    placeholder="Enter your NTID" autoComplete="off" onChange={changeHandle} />
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                        <div className="form-group row pt-3">
                            <div className="col-sm-1"></div>
                            <label htmlFor="password" className="col-sm-2 col-form-label" style={{ paddingLeft: "2px" }}><b>Password</b></label>
                            <div className="col-sm-8">
                                <input type="password" name="password" id="password" autoComplete="off" value={login.password} id="idinputpassword" required className="form-control" placeholder="Enter your password" onChange={changeHandle} />
                            </div>
                            <div className="col-sm-1"></div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12 text-center">

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="flex justify-content-center items-center">
                        <input type="submit" value="Let me in!" className="loginBtn flex justify-content-center items-center" />
                    </Modal.Footer>
                </form>
            </Modal>

            <div>
                <Modal show={adminShow} size="lg" backdrop="static">
                    <Modal.Header>
                        <Modal.Title><h4>Agency Champs</h4></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form style={{ padding: "0.5rem" }} onSubmit={getBName} >
                            <Loader loaded={isLoading} content="Loading..."></Loader>
                            <div className="form-group row pt-3">
                                <label htmlFor="inputPassword3" className="col-sm-3 col-form-label" style={{ paddingLeft: "3rem", fontSize: "1.2rem" }}><b>Branch</b></label>
                                <div className="col-sm-7">
                                    <Select name="branchName" id="branchName" onChange={handleSelectBN} options={branchName} placeholder="Select Branch Name or Code" value={selectedOptionsBN} />
                                </div>
                                <div className="col-sm-2"></div>

                            </div>

                            <div className="form-group row pt-4">
                                <div className="col-sm-4 text-center"></div>
                                <div className="col-sm-4 text-center">
                                    <input type="submit" value="Submit" className="loginBtn flex justify-content-center items-center" />
                                </div>
                                <div className="col-sm-4 text-center"> </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer className="flex justify-content-center items-center">

                    </Modal.Footer>

                </Modal>
            </div>
        </>
    )
    return (
        <>
            {userLogin}
        </>
    )
}
export default Login;
// export const vmym= "http://localhost:5554/leaderboard/top-managers";
// export const advmym="http://localhost:5554/leaderboard/super/top-managers";
// export const branchName="http://localhost:5554/leaderboard/branchname";

// export const vmym="https://isessionportal.iciciprulife.com/leaderboard/top-managers";
// export const advmym="https://isessionportal.iciciprulife.com/leaderboard/super/top-managers";

export const vmym="https://api.iciciprulife.com/agency-champs/top-managers";
export const advmym="https://api.iciciprulife.com/agency-champs/super/top-managers";
export const jwt="https://api.iciciprulife.com/agency-champs/jwt";
export const refreshJwt="https://api.iciciprulife.com/agency-champs/refresh-token";
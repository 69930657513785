import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../css/bootstrap.min.css';

function Layout() {
    return (
        <>
            <Outlet />
        </>
    );
}

export default Layout;